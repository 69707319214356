import React from 'react';
import { Skeleton, Typography } from "@mui/material";

const LoadingText = (props) => {
  const { children, loading, style } = props;
  return (
      <Typography
          style={style}
      >
        {loading ?
            <Skeleton sx={{minWidth: 100}}/> :
            children
        }
      </Typography>
  );
};

export default LoadingText;
