import { useMoralisDapp } from "providers/MoralisDappProvider/MoralisDappProvider";
import { useMoralis } from "react-moralis";
import { getEllipsisTxt } from "helpers/formatters";
import Blockie from "./Blockie";
import { useEffect } from "react";
import { PRIMARY_COLOR } from "../colors/themeColors";
const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: "fit-content",
    borderRadius: "12px",
    backgroundColor: PRIMARY_COLOR,
    border: "#ccc solid",
    cursor: "pointer",
  },
  text: {
    color: "#21BF96",
  },
};

function Account() {
  const { authenticate, isAuthenticated, logout, auth } = useMoralis();
  const { walletAddress } = useMoralisDapp();

  const authHandler = async () => {
    const res = await authenticate({ signingMessage: "Sign for Care for Wild" });
  }

  useEffect(() => {
    console.log("Auth state", auth)
  }, [auth]);

  if (!isAuthenticated) {
    return (
      <div style={styles.account} onClick={authHandler}>
        <p style={styles.text}>Authenticate</p>
      </div>
    );
  }

  return (
    <div style={styles.account} onClick={() => logout()}>
      <p style={{ marginRight: "5px", ...styles.text }}>{getEllipsisTxt(walletAddress, 6)}</p>
      <Blockie currentWallet scale={3} />
    </div>
  );
}

export default Account;
