import { CLIENT } from "./contractInfo";

let appBarTextSwitch;

if (CLIENT === "VULPRO") {
  appBarTextSwitch = {
    mainPage: "Adopt Vultures"
  }
}

else if (CLIENT === "ELEPHANT_WHISPERS") {
  appBarTextSwitch = {
    mainPage: "Adopt Elephants"
  }
}

else if (CLIENT === "MANDELA") {
  appBarTextSwitch = {
    mainPage: "Purchase"
  }
}

else if (CLIENT === "GREYTON") {
  appBarTextSwitch = {
    mainPage: "Adopt"
  }
}

else if (CLIENT === "C4F") {
  appBarTextSwitch = {
    mainPage: "Adopt a Rhino"
  }
}

else {
  appBarTextSwitch = {
    mainPage: "Home"
  }
}

export const appBarText = appBarTextSwitch