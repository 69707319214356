import {Card, Timeline, Typography, Button, Image} from "antd";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useMoralis} from "react-moralis";
import axios from 'axios';
import { Box, CircularProgress, Container, ImageList, ImageListItem, Link, Skeleton } from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchAllNFTs, selectLoadedNFTs } from "../store/slices/nftSlices/nftSlice";
import { mainPageStyles } from "../styles/styles";
import PrimaryButton from "./Buttons/PrimaryButton";

const {Text} = Typography;

// Flow:
// Set the steward contract address
// Get the current number of tokens, we'll always create from 0 upwards...
// totalTokens = 100
// from the steward, can get the nft it is managing,
// from the NFT can get the BaseURI for the NFT's metadata
// can append tokenId.json to baseURI to get each token's metadata which will contain the images

const styles = mainPageStyles;

const AdoptionCard = (props) => {
    const {steward, web3} = props;
    const [showDetails, setShowDetails] = useState(false);
    const [nftDetails, setNftDetails] = useState({});
    //console.log('AdoptionCard: ', steward)
    return (
        <Card style={styles.card} title={<h1 style={styles.title}>{steward.name}</h1>}>
            <Image
                src={`${steward.imageUri}?w=248&fit=crop&auto=format` ?? steward.imageUrl}
                alt="nologo"
                width="100%"
                //height="24px"
                preview={false}
                style={{alignSelf: 'center', borderRadius: '5px'}}
            />
            <h1 style={styles.description}>{steward.description}</h1>
            <Link
                component={RouterLink}
                to={{pathname: '/nftDetails', state: {steward: steward}}}
                style={{
                    textDecoration: 'none'
                }}
            >
                <PrimaryButton
                    style={{
                        width: "100%",
                        marginTop: "15px",
                        borderRadius: "0.6rem",
                        height: "50px",
                    }}
                    onClick={() => {
                    }}
                    disabled={false}
                >
                    Adopt
                </PrimaryButton>
            </Link>
        </Card>
    )
}

export default function QuickStart({isServerInfo}) {
    const [loading, setLoading] = useState(false);
    const {Moralis, web3, isAuthenticated, user} = useMoralis();

    const loadedNFTs = useSelector(selectLoadedNFTs);

    const dispatch = useDispatch();


    const loadNftDetails = async () => {
        if (Object.keys(loadedNFTs).length === 0){
            setLoading(true);
            await dispatch(fetchAllNFTs({ web3 }));
            setLoading(false);
        }
        else {
          await dispatch(fetchAllNFTs({ web3 }));
        }
    }

    useEffect(async () => {
        if(isAuthenticated) {
          await loadNftDetails();
        }
    }, [isAuthenticated]);

  if (loading) {
    return (
        <Container style={{alignItems: 'center'}}>
          <ImageList variant={"masonry"} cols={3} gap={8}>
            {
              [0,1,2,3,4,5,6].map((steward, index) => (
                      <ImageListItem key={index}>
                          <Card style={styles.card}
                                title={
                                  <Skeleton variant="rectangular" width={200} height={50} />
                                }
                          >
                            <Skeleton variant="rectangular" width={250} height={200} />
                            <Skeleton variant="text" />
                            <Skeleton variant="rectangular" width={"100%"} height={50} />
                          </Card>
                      </ImageListItem>
                  )
              )
            }
          </ImageList>
        </Container>
    )
  }
//Object.keys(nftDetails).length !== 0
    if (Object.keys(loadedNFTs).length !== 0) {
        return (
            <Container style={{alignItems: 'center'}}>
                <ImageList variant={"masonry"} cols={3} gap={8}>
                    {
                        loadedNFTs.map((steward, index) => (
                                <ImageListItem key={index}>
                                    <AdoptionCard steward={steward} web3={web3}/>
                                </ImageListItem>
                            )
                        )
                    }
                </ImageList>
            </Container>
        );
    }

    return (
        <div style={{display: "grid", gap: "10px"}}>
                <div>
                    {loading && <CircularProgress/>}
                    <h1 style={{
                        ...styles.title,
                        color: 'black'
                    }}>{isAuthenticated ? "Loading..." : "Authentication required"}</h1>
                    {
                        (!loading && isAuthenticated) &&
                        <PrimaryButton
                            onClick={() => loadNftDetails()}
                            disabled={false}
                            text={"Reload NFTs"}
                        >
                          Reload
                        </PrimaryButton>
                    }
                </div>

        </div>
    );
}
