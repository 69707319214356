import { CLIENT } from "../constants/contractInfo";

const greytonStyles = {
  appBar: {
    display: "flex",
    fontSize: "17px",
    fontWeight: "500",
    width: "100%",
    justifyContent: "center",
    background: "white",
  },
  appBarText: {
    color: 'black',
    textAlign: 'left'
  },
  button: {
    width: "100%",
    marginTop: "15px",
    borderRadius: "0.6rem",
    height: "50px",
    backgroundColor: "#b7c68b",
    border: "2px solid rgba(0, 0, 0, 0.06)",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
    background: "#3d4148",
    alignItems: 'center'
  },
}

const defaultStyles = {
  appBar: {
    display: "flex",
    fontSize: "17px",
    fontWeight: "500",
    width: "100%",
    justifyContent: "center",
    background: "#3d4148",
  },
  appBarText: {
    color: 'white',
    textAlign: 'left'
  },
  button: {
    width: "100%",
    marginTop: "15px",
    borderRadius: "0.6rem",
    height: "50px",
    backgroundColor: "#A4CC28",
    border: "2px solid rgba(0, 0, 0, 0.06)",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
    background: "#3d4148",
    alignItems: 'center'
  },
}

export const mainPageStyles = {
  title: {
    fontSize: "24px",
    fontWeight: "700",
    color: "white",
    fontFamily: 'Roboto',
    textTransform: 'uppercase'
  },
  description: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#88a12b",
    fontFamily: 'Roboto'
  },
  text: {
    fontSize: "16px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
    background: "#3d4148",
    alignItems: 'center'
  },
  timeline: {
    marginBottom: "-45px",
  },
};

export const detailsStyles = {
  mainContainer: {
    alignItems: 'center',
    flex: 1,
    width: "100%",
    // backgroundColor: '#ccc'
  },
  title: {
    fontSize: "24px",
    fontWeight: "700",
    color: "white",
    fontFamily: 'Roboto',
    textTransform: 'uppercase'
  },
  detailsKey: {
    fontSize: "16px",
    fontWeight: "700",
    color: "white",
    marginRight: "10px"
  },
  subtitle: {
    fontSize: "20px",
    fontWeight: "700",
    color: "white",
    marginBottom: '20px',
    fontFamily: 'Roboto',
  },
  description: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#88a12b",
    fontFamily: 'Roboto'
  },
  text: {
    fontSize: "16px",
    fontWeight: "700",
    color: "white",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
    background: "#3d4148",
    alignItems: 'center',
    maxWidth: "45%",
    minWidth: "300px"
  },
  timeline: {
    marginBottom: "-45px",
  },
};

let switchAppBarStyle = defaultStyles.appBar;
let switchAppBarTextStyle = defaultStyles.appBarText;
let switchPrimaryButtonStyle = defaultStyles.button;

switch (CLIENT) {
  case "GREYTON":
    switchAppBarStyle = greytonStyles.appBar;
    switchAppBarTextStyle = greytonStyles.appBarText;
    switchPrimaryButtonStyle = greytonStyles.button;
    break;
  default:
    switchAppBarStyle = defaultStyles.appBar;
    switchAppBarTextStyle = defaultStyles.appBarText;
    switchPrimaryButtonStyle = defaultStyles.button;
    break;
}

export const appBarStyle = switchAppBarStyle;
export const appBarTextStyle = switchAppBarTextStyle;
export const primaryButtonStyle = switchPrimaryButtonStyle;