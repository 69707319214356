import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STEWARD_ADDRESS } from "../../../constants/contractInfo";
import { CoinGeckoApiService } from "../../../services/coinGeckoApiService";
import { getAxios } from "../../../services/apiService";

const erc721ContractJSON = require('./../../../contracts/ERC721.json');
const stewardContractJSON = require('./../../../contracts/Steward.json');

const STEWARD_ABI = stewardContractJSON.abi;
const ERC721_ABI = erc721ContractJSON.abi;

const coinGeckoApiService = new CoinGeckoApiService();
const initialState = {
  loadedNFTs: [],
  stewardContract: {},
  totalTokens: 0,
  beneficiaryFund: 0,
  nftAddress: '',
  nftContract: {},
  status: 'idle',
  error: null
};

export const fetchAllNFTs = createAsyncThunk('nft/fetchAllNFTs', async (params) => {

  const { web3 } = params;

  try {
    let steward = new web3.eth.Contract(
        STEWARD_ABI,
        STEWARD_ADDRESS
    );

    let totalTokens = await steward.methods.totalTokens().call();
    let beneficiaryFund = await steward.methods.beneficiaryFund().call();
    let nftAddress = await steward.methods.nft().call();
    let nft = new web3.eth.Contract(ERC721_ABI, nftAddress);

    let baseURI = await nft.methods.baseURI().call();
    //let baseURI = res.value;
    console.log("uri: ", baseURI);

    let stewardDetails = [];

    for (let i = 0; i < totalTokens; i++) {
      const result = await getAxios(`${baseURI}/${i}.json`);
      if (result.isSuccess) {
        const tokenId = i;
        const metaData = result.value;
        const details = {
          imageUri: metaData.image,
          description: metaData.description,
          name: metaData.name,
          tokenId: tokenId
        }
        stewardDetails.push(details);
      } else {
        console.log('error: ', result.errorMessage);
        throw new Error(result.errorMessage);
      }
    }

    const result = {
      totalTokens: totalTokens,
      beneficiaryFund: beneficiaryFund,
      nftAddress: nftAddress,
      nft: nft,
      stewardDetails: stewardDetails,
    }

    return result;

  } catch (err) {
    const errorMessage = err.message;
    throw new Error(errorMessage);
  }
});

export const fetchNFTDetails = createAsyncThunk('nft/fetchNFTDetails', async (params) => {

  const { web3, tokenId } = params;

  try {
    let steward = new web3.eth.Contract(
        STEWARD_ABI,
        STEWARD_ADDRESS
    );

    const tokenIdBN = new web3.utils.BN(tokenId)

    const prices = await steward.methods.prices(tokenIdBN).call()
    const totalsCollected = await steward.methods.totalsCollected(tokenIdBN).call()
    const timesLastCollected = await steward.methods.timesLastCollected(tokenIdBN).call()
    const deposits = await steward.methods.deposits(tokenIdBN).call()
    const timesAcquired = await steward.methods.timesAcquired(tokenIdBN).call()
    const floorPrices = await steward.methods.floorPrices(tokenIdBN).call()
    let beneficiaryFund = await steward.methods.beneficiaryFund().call();

    const collectedDate = timesAcquired;
    const lastCollectedDate = timesLastCollected;
    const deposit = web3.utils.fromWei(deposits, 'ether');
    const price = web3.utils.fromWei(prices, 'ether');
    const floorPrice = web3.utils.fromWei(floorPrices, 'ether');
    const totalCollected = web3.utils.fromWei(totalsCollected, 'ether');
    const depositWei = deposits;
    const totalCollectedWei = totalsCollected;

    const maticRes = await coinGeckoApiService.maticToZar();
    let depositZar;
    let currentPriceZar;
    let floorPriceZar;
    let totalCollectedZar;

    let maticPriceZar = 24.23;
    if (maticRes.isSuccess) {
       maticPriceZar = maticRes.value;
       depositZar = deposit * maticPriceZar;
       currentPriceZar = price * maticPriceZar;
       floorPriceZar = floorPrice * maticPriceZar;
       totalCollectedZar = totalCollected * maticPriceZar;
    } else {
      maticPriceZar = 24.23;
      depositZar = deposit * maticPriceZar;
      currentPriceZar = price * maticPriceZar;
      floorPriceZar = floorPrice * maticPriceZar;
      totalCollectedZar = totalCollected * maticPriceZar;
    }


    const result = {
      tokenId: tokenId,
      currentPrice: price,
      totalsCollected: totalCollected,
      totalCollectedWei: totalCollectedWei,
      timesLastCollected: lastCollectedDate,
      beneficiaryFund: beneficiaryFund,
      deposits: deposit,
      depositWei: depositWei,
      timesAcquired: collectedDate,
      floorPrice: floorPrice,
      depositZar: depositZar.toFixed(2),
      currentPriceZar: currentPriceZar.toFixed(2),
      floorPriceZar: floorPriceZar.toFixed(2),
      totalCollectedZar: totalCollectedZar.toFixed(2),
      maticToZar: maticPriceZar
    }
    return result;

  } catch (err) {
    const errorMessage = err.message;
    throw new Error(errorMessage);
  }
});

const exploreNFTsSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    setLoadedNFTs(state, action) {
      state.loadedNFTs = action.payload;
    },
  },
  extraReducers(builder) {
    builder
        .addCase(fetchAllNFTs.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchAllNFTs.fulfilled, (state, action) => {
          const { totalTokens, beneficiaryFund, nftAddress, nft, stewardDetails } = action.payload;
          state.status = 'succeeded';
          state.totalTokens = totalTokens;
          state.beneficiaryFund = beneficiaryFund;
          state.nftAddress = nftAddress;
          state.loadedNFTs = stewardDetails;
        })
        .addCase(fetchAllNFTs.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(fetchNFTDetails.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchNFTDetails.fulfilled, (state, action) => {
          const {
            tokenId,
            prices,
            totalsCollected,
            timesLastCollected,
            beneficiaryFund,
            deposits,
            timesAcquired,
            floorPrices
          } = action.payload;

          state.status = 'succeeded';

          const existingNft = state.loadedNFTs.find(nft => nft.tokenId === tokenId);
          const existingNftIndex = state.loadedNFTs.findIndex(nft => nft.tokenId === tokenId);
          let updatedState = state.loadedNFTs;
          const updatedNft = { ...existingNft, ...action.payload };
          updatedState[existingNftIndex] = updatedNft;
          console.log("updatedNft: ", updatedNft)
          state.loadedNFTs = updatedState;
        })
        .addCase(fetchNFTDetails.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
  }
});

export default exploreNFTsSlice.reducer;
export const {
  setLoadedNFTs,
} = exploreNFTsSlice.actions;

export const selectLoadedNFTs = (state) => state.nft.loadedNFTs;
export const selectLoadedNftById = (state, tokenId) => state.nft.loadedNFTs.find(nft => nft.tokenId === tokenId);
export const selectLoadedNftStatus = (state) => state.nft.status;
