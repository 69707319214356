import { configureStore, combineReducers } from '@reduxjs/toolkit';
import nftReducer from "./slices/nftSlices/nftSlice";

const combinedReducer = combineReducers({
  nft: nftReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'user/resetUser') { // Reset the Redux store
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer
});

export default store;