import axios from "axios";

export const getAxios = async (url) => {
  try {
    const result = await axios.get(`${url}`);
    return {
      isSuccess: true,
      value: result.data,
      errorMessage: '',
      httpCode: result.status
    };
  } catch (ex) {
    console.log(`baseApiService (${url}): `, ex.message);
    return {
      isSuccess: false,
      value: {},
      errorMessage: ex.response.data,
      httpCode: ex.response.status
    };
  }
}