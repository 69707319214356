const CLIENTS = [
  "TEST",
  "VULPRO",
  "ELEPHANT_WHISPERS",
  "MANDELA",
  "GREYTON",
  "C4F",
];
const TEST_STEWARD_ADDRESS = "0xB5012496c8784bc8809D08EEA983Daa77Ce3Bf59";
const VULPRO_STEWARD_ADDRESS = "0xb87329499fd041ad7Af6f115a47607db9e790e0a";
const VULPRO_NFT_ADDRESS = "0x621748b09521a07421fdb5c8d9fa513986975022";
const ELEPHANT_WHISPERS_STEWARD_ADDRESS =
  "0xA997FB97f1511237D0c10d934800835C00254c1d";
const ELEPHANT_WHISPERS_NFT_ADDRESS =
  "0x04bb96b0dB93d29A35eF59A2a198145c29c16FD5";
const MANDELA_STEWARD_ADDRESS = "0xcEA46e96c7b394c1F78ba27C621eb5852422d4E9";
const MANDELA_NFT_ADDRESS = "0x09C4E125C6d5d44a4F74687002bE65AD2aF4B7bb";
const GREYTON_STEWARD_ADDRESS = "0x3b6764E6A44a2A52e8406ea990F96375FF9E03b7";
const GREYTON_NFT_ADDRESS = "0x7ea4b137a7df27027a230A473adA1d80Eeb50270";
const C4F_STEWARD_ADDRESS = "0x9Ca689BEe135Ba8E7d2a86f30E659A367DdF5645";
const C4F_NFT_ADDRESS = "0xC90A624bf110332532540cC41503B326dcb403C0";
export const CLIENT = CLIENTS[5];

let address_switch = TEST_STEWARD_ADDRESS;
let nft_address_switch = VULPRO_NFT_ADDRESS;
switch (CLIENT) {
  case "VULPRO":
    address_switch = VULPRO_STEWARD_ADDRESS;
    nft_address_switch = VULPRO_NFT_ADDRESS;
    break;
  case "ELEPHANT_WHISPERS":
    address_switch = ELEPHANT_WHISPERS_STEWARD_ADDRESS;
    nft_address_switch = ELEPHANT_WHISPERS_NFT_ADDRESS;
    break;
  case "MANDELA":
    address_switch = MANDELA_STEWARD_ADDRESS;
    nft_address_switch = MANDELA_NFT_ADDRESS;
    break;
  case "GREYTON":
    address_switch = GREYTON_STEWARD_ADDRESS;
    nft_address_switch = GREYTON_NFT_ADDRESS;
    break;
  case "C4F":
    address_switch = C4F_STEWARD_ADDRESS;
    nft_address_switch = C4F_NFT_ADDRESS;
    break;
  default:
    address_switch = TEST_STEWARD_ADDRESS;
    nft_address_switch = VULPRO_NFT_ADDRESS;
    break;
}

export const STEWARD_ADDRESS = address_switch;
export const NFT_ADDRESS = nft_address_switch;
