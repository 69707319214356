import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {MoralisProvider} from "react-moralis";
import "./index.css";
import {MoralisDappProvider} from "./providers/MoralisDappProvider/MoralisDappProvider";
import { Provider } from "react-redux";
import store from "./store/store";
require('dotenv').config()

/** Get your free Moralis Account https://moralis.io/ */

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
      <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
          <MoralisDappProvider>
              <App isServerInfo/>
          </MoralisDappProvider>
      </MoralisProvider>
    </Provider>,
    // </React.StrictMode>,
    document.getElementById("root")
);
