import React from 'react';
import PropTypes from 'prop-types';
import { Button } from "antd";
import { primaryButtonStyle } from "../../styles/styles";

const PrimaryButton = (props) => {
  const { children, style, onClick, text, disabled } = props;
  return (
      <Button
          type="primary"
          size="large"
          onClick={onClick}
          disabled={disabled}
          style={{ ...primaryButtonStyle, ...style }}
      >
        {children && children}
      </Button>
  );
};

PrimaryButton.propTypes = {
  children: PropTypes.object,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool
}

export default PrimaryButton;
