import React, { useState } from 'react';
import { Container, Link } from "@mui/material";
import { CLIENT } from "../../constants/contractInfo";

const ClientLogo = () => {
  const [logoFocus, setLogoFocus] = useState(false);
  if (CLIENT === "VULPRO"){
    return (
        <img
            src={
              "https://vulpro.com/wp-content/uploads/2019/11/Vulpro_logo_white.png"
            }
            onMouseEnter={() => setLogoFocus(false)}
            onMouseLeave={() => setLogoFocus(false)}
            alt="nologo"
            width={logoFocus ? "95" : "95"}
            preview={false}
            style={{ borderRadius: "75px", transform: `translate(${0}px, ${logoFocus ? 45 : 0}px)` }}
        />
    )
  }

  if (CLIENT === "ELEPHANT_WHISPERS"){
    return (
        <Link
            style={{width: 400}}
          to={"/quickstart"}
        >
          <img
              src={
                "https://www.elephantwhispers.co.za/wp-content/uploads/2019/04/EW-LOGO-2.png"
              }
              onMouseEnter={() => setLogoFocus(false)}
              onMouseLeave={() => setLogoFocus(false)}
              alt="nologo"
              width={logoFocus ? "150px" : "100"}
              preview={false}
              style={{
                borderRadius: "75px", transform: `translate(${0}px, ${logoFocus ? 45 : 0}px)`,
              }}
          />
        </Link>
    )
  }

  if (CLIENT === "MANDELA"){
    return (
        <Link
            style={{width: 400}}
            to={"/quickstart"}
        >
          <img
              src={
                "https://static.wixstatic.com/media/6f0a98_baaa12078c3246be89a4d7e05acd5540~mv2.png/v1/crop/x_0,y_0,w_602,h_302/fill/w_209,h_105,al_c,q_85,usm_0.66_1.00_0.01/LMFF_Full_logo-removebg-preview.webp"
              }
              alt="nologo"
              width={"100"}
              preview={false}
              style={{
                borderRadius: "75px",
              }}
          />
        </Link>
    )
  }

  if (CLIENT === "GREYTON"){
    return (
        <Link
            style={{width: 400}}
            to={"/quickstart"}
        >
          <img
              src={
                "https://greytonfarmsanctuary.org/wp-content/uploads/2020/08/Greyton-Farm-Logo.png"
              }
              onMouseEnter={() => setLogoFocus(false)}
              onMouseLeave={() => setLogoFocus(false)}
              alt="nologo"
              width={logoFocus ? "150px" : "100"}
              preview={false}
              style={{
                borderRadius: "75px", transform: `translate(${0}px, ${logoFocus ? 45 : 0}px)`,
              }}
          />
        </Link>
    )
  }

  if (CLIENT === "C4F"){
    return (
        <Link
            style={{width: 400}}
            to={"/quickstart"}
        >
          <img
              src={
                "https://www.careforwild.co.za/wp-content/uploads/2020/08/new-web-logo-500.png"
              }
              onMouseEnter={() => setLogoFocus(false)}
              onMouseLeave={() => setLogoFocus(false)}
              alt="nologo"
              width={logoFocus ? "150px" : "100"}
              preview={false}
              style={{
                borderRadius: "75px",
                border: "2px solid #e7eaf3",
                transform: `translate(${0}px, ${logoFocus ? 45 : 20}px)`,
              }}
          />
        </Link>
    )
  }

  if (CLIENT === "TEST") {
    return (
        <img
            src={
              "https://www.careforwild.co.za/wp-content/uploads/2020/08/new-web-logo-500.png"
            }
            onMouseEnter={() => setLogoFocus(true)}
            onMouseLeave={() => setLogoFocus(false)}
            alt="nologo"
            width={logoFocus ? "150px" : "95"}
            preview={false}
            style={{ borderRadius: "75px", transform: `translate(${0}px, ${logoFocus ? 45 : 20}px)`, border: "2px solid #e7eaf3" }}
        />
    )
  }

  return (
        <img
            src={
              "https://vulpro.com/wp-content/uploads/2019/11/Vulpro_logo_white.png"
            }
            onMouseEnter={() => setLogoFocus(true)}
            onMouseLeave={() => setLogoFocus(false)}
            alt="nologo"
            width={logoFocus ? "150px" : "95"}
            preview={false}
            style={{ borderRadius: "75px", transform: `translate(${0}px, ${logoFocus ? 45 : 0}px)`, border: "2px solid #e7eaf3" }}
        />
  );
};

export default ClientLogo;
