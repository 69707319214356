import { getAxios } from "./apiService";

const BASE_URL = "https://api.coingecko.com/api/v3";

export class CoinGeckoApiService {
  async maticToZar() {
    const contract_address = "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0"
    const apiResult = await getAxios(`${BASE_URL}/simple/token_price/ethereum?contract_addresses=${contract_address}&vs_currencies=zar`);

    if (!apiResult.isSuccess) {
      return {
        isSuccess: false,
        value: {},
        errorMessage: apiResult.errorMessage,
        httpCode: apiResult.httpCode
      };
    }

    return {
      isSuccess: true,
      value: apiResult.value[contract_address].zar,
      errorMessage: '',
      httpCode: apiResult.httpCode
    };
}
}