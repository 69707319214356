import { CLIENT } from "../constants/contractInfo";

let primarySwitch;
let backgroundSwitch;

switch (CLIENT){
  case "TEST":
    primarySwitch = "#3d4148"
    break;
  case "VULPRO":
    primarySwitch = "#3d4148"
    break;
  case "ELEPHANT_WHISPERS":
    primarySwitch = "#3d4148"
    break;
  case "MANDELA":
    primarySwitch = "#3d4148"
    break;
  case "GREYTON":
    primarySwitch = "white"
    break;
  default:
    primarySwitch = "#3d4148"
    break;
}

export const PRIMARY_COLOR = primarySwitch;
export const BACKGROUND_COLOR = "#ddd";