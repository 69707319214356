import { Card, Button, Image } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMoralis } from "react-moralis";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  LinearProgress,
  Link,
  Modal,
  Skeleton,
  Slider,
  TextField,
  Typography as Text,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { STEWARD_ADDRESS } from "../constants/contractInfo";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNFTDetails,
  selectLoadedNftById,
} from "../store/slices/nftSlices/nftSlice";
import { CheckCircleOutlined, Close, PublicRounded } from "@mui/icons-material";
import { detailsStyles } from "../styles/styles";
import LoadingText from "./Text/Text";
import PrimaryButton from "./Buttons/PrimaryButton";

const stewardContractJSON = require("../contracts/Steward.json");

const STEWARD_ABI = stewardContractJSON.abi;

const MINIMUM_PRICE = 0.1;
const MAXIMUM_PRICE = 13;

// Flow:
// Set the steward contract address
// Get the current number of tokens, we'll always create from 0 upwards...
// totalTokens = 100
// from the steward, can get the nft it is managing,
// from the NFT can get the BaseURI for the NFT's metadata
// can append tokenId.json to baseURI to get each token's metadata which will contain the images
const getNftDetails = async (web3, stewardAddress, tokenId1) => {
  let steward = new web3.eth.Contract(STEWARD_ABI, stewardAddress);

  const tokenId = new web3.utils.BN(tokenId1);

  const prices = await steward.methods.prices(tokenId).call();
  const totalsCollected = await steward.methods.totalsCollected(tokenId).call();
  const timesLastCollected = await steward.methods
    .timesLastCollected(tokenId)
    .call();
  const deposits = await steward.methods.deposits(tokenId).call();
  const timesAcquired = await steward.methods.timesAcquired(tokenId).call();
  const floorPrices = await steward.methods.floorPrices(tokenId).call();
  let beneficiaryFund = await steward.methods.beneficiaryFund().call();

  const collectedDate = dayjs.unix(timesAcquired).format("ddd MMM YYYY");
  const lastCollecteDate = dayjs
    .unix(timesLastCollected)
    .format("ddd MMM YYYY");
  const deposit = web3.utils.fromWei(deposits, "ether");
  const price = web3.utils.fromWei(prices, "ether");
  const floorPrice = web3.utils.fromWei(floorPrices, "ether");
  const totalCollected = web3.utils.fromWei(totalsCollected, "ether");

  const result = {
    prices: price,
    totalsCollected: totalCollected,
    timesLastCollected: lastCollecteDate,
    beneficiaryFund: beneficiaryFund,
    deposits: deposit,
    timesAcquired: collectedDate,
    floorPrices: floorPrice,
  };

  console.log("result: ", result);

  return result;
};

const styles = detailsStyles;

const PriceSlider = ({
  currentPrice,
  price,
  priceFloor,
  priceChangeHandler,
}) => {
  const [minPrice, setMinPrice] = useState(2 * currentPrice);
  const [maxPrice, setMaxPrice] = useState(13 * currentPrice);
  const [value, setValue] = useState(2 * currentPrice);

  useEffect(() => {
    if (maxPrice === 0) {
      setMinPrice(MINIMUM_PRICE);
      setMaxPrice(MAXIMUM_PRICE);
    } else if (currentPrice < priceFloor) {
      setMinPrice(priceFloor);
    } else {
      setMinPrice(2 * currentPrice);
    }
  }, []);

  useEffect(() => {
    setValue(Number(price));
    //console.log("price: ", price)
  }, [price]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    priceChangeHandler(newValue);
  };

  const marks = [
    {
      value: minPrice,
      label: String(Number(minPrice).toFixed(6)),
    },
    {
      value: maxPrice,
      label: String(Number(maxPrice).toFixed(6)),
    },
  ];

  function valuetext(value) {
    return `${value} ETH`;
  }

  return (
    <Box sx={{ width: 200 }}>
      <Slider
        aria-label="Always visible"
        defaultValue={minPrice}
        getAriaValueText={valuetext}
        step={maxPrice / 100}
        max={maxPrice}
        min={minPrice}
        //marks={marks}
        value={typeof value === "number" ? value : minPrice}
        onChange={handleSliderChange}
        //valueLabelDisplay="on"
      />
    </Box>
  );
};

const PriceCard = ({ onSubmit, nftDetails, loading }) => {
  const { currentPrice, floorPrice, maticToZar } = nftDetails;
  const [price, setPrice] = useState(2 * currentPrice);
  const [priceZar, setPriceZar] = useState(2 * currentPrice * maticToZar);
  const [priceMatic, setPriceMatic] = useState(2 * currentPrice);

  const priceFloor = floorPrice;
  const [minPrice, setMinPrice] = useState(2 * currentPrice);
  const [maxPrice, setMaxPrice] = useState(13 * currentPrice);

  useEffect(() => {
    if (currentPrice < priceFloor) {
      setMinPrice(priceFloor);
      setPrice(priceFloor);
      setPriceZar(priceFloor * maticToZar);
    } else if (currentPrice === 0) {
      setMinPrice(MINIMUM_PRICE);
      setMaxPrice(MAXIMUM_PRICE);
      setPrice(MINIMUM_PRICE);
      setPriceZar(MINIMUM_PRICE * maticToZar);
    } else {
      setMinPrice(2 * currentPrice);
      setPrice(2 * currentPrice);
      setPriceZar(2 * currentPrice * maticToZar);
    }
  }, [nftDetails]);

  const submitHandler = () => {
    onSubmit(price);
  };

  const zarPriceChangeHandler = (price) => {
    setPrice(price / maticToZar);
    setPriceZar(price);
    setPriceMatic(price / maticToZar);
  };

  const maticPriceChangeHandler = (price) => {
    setPrice(price);
    setPriceZar(price * maticToZar);
    setPriceMatic(price);
  };

  if (loading) {
    return (
      <Card style={styles.card} title={<h1 style={styles.title}>Adopt</h1>}>
        <Skeleton variant="text" />
        <Skeleton variant="rectangular" width={"100%"} height={70} />
        <Skeleton variant="text" />
        <Skeleton variant="rectangular" width={"100%"} height={50} />
      </Card>
    );
  }

  return (
    <Card
      style={{ ...styles.card, alignItems: "center", width: "500px" }}
      title={<h1 style={styles.title}>Adopt</h1>}
    >
      <h2 style={styles.subtitle}>Set Price</h2>
      {/*<PriceSlider />*/}
      <Grid
        container
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <TextField
          id="outlined-basic"
          label="Price (ZAR)"
          variant="outlined"
          value={typeof priceZar === "number" ? priceZar.toFixed(2) : priceZar}
          style={{ width: 120 }}
          InputLabelProps={{ style: { color: "white" } }}
          sx={{
            "& .MuiInputBase-root": {
              color: "white",
              height: 60,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "white",
              },
            },
          }}
          //defaultValue={minPrice}
          onChange={(e) => zarPriceChangeHandler(e.target.value)}
        />
        <Text style={styles.text}>{`${Number(price).toFixed(4)} MATIC`}</Text>
      </Grid>
      <Container style={{ padding: 10 }}>
        <PriceSlider
          price={price}
          priceZar={priceZar}
          currentPrice={currentPrice}
          priceFloor={priceFloor}
          priceChangeHandler={maticPriceChangeHandler}
        />
      </Container>
      <Text style={styles.text}>
        {`Monthly Contribution: R ${(
          ((Number(price) - Number(currentPrice)) / 12) *
          maticToZar
        ).toFixed(2)}`}
      </Text>
      <PrimaryButton
        style={{
          width: "100%",
          marginTop: "15px",
          borderRadius: "0.6rem",
          height: "50px",
        }}
        onClick={submitHandler}
        disabled={false}
      >
        Adopt
      </PrimaryButton>
    </Card>
  );
};

const DetailsCard = ({ nftDetails, loading }) => {
  if (false) {
    return (
      <Card style={styles.card} title={<h1 style={styles.title}>Details</h1>}>
        <Skeleton variant="rectangular" width={210} height={118} />
      </Card>
    );
  }
  return (
    <Card style={styles.card} title={<h1 style={styles.title}>Details</h1>}>
      <Grid
        container
        style={{ justifyContent: "space-between", flexDirection: "row" }}
      >
        <LoadingText style={styles.detailsKey} loading={loading}>
          {`Current Price:`}
        </LoadingText>
        <LoadingText style={styles.text} loading={loading}>
          {`R${nftDetails.currentPriceZar}`}
        </LoadingText>
      </Grid>
      <Grid
        container
        style={{ justifyContent: "space-between", flexDirection: "row" }}
      >
        <LoadingText style={styles.detailsKey} loading={loading}>
          {`Total Collected So Far:`}
        </LoadingText>
        <LoadingText style={styles.text} loading={loading}>
          {`R${nftDetails.totalCollectedZar}`}
        </LoadingText>
      </Grid>
      <Grid
        container
        style={{ justifyContent: "space-between", flexDirection: "row" }}
      >
        <LoadingText style={styles.detailsKey} loading={loading}>
          {``}
        </LoadingText>
        <LoadingText style={styles.text} loading={loading}>
          {`${nftDetails.totalCollectedWei} WEI`}
        </LoadingText>
      </Grid>
      {/*<Grid container style={{ justifyContent: 'space-between', flexDirection: 'row' }}>*/}
      {/*  <Text style={styles.detailsKey}>*/}
      {/*    {`Patronage Last Collected:`}*/}
      {/*  </Text>*/}
      {/*  <Text style={styles.text}>*/}
      {/*    {`${dayjs.unix(nftDetails.timesLastCollected).format("DD MMM YYYY")}`}*/}
      {/*  </Text>*/}
      {/*</Grid>*/}
      <Grid
        container
        style={{ justifyContent: "space-between", flexDirection: "row" }}
      >
        <LoadingText style={styles.detailsKey} loading={loading}>
          {`Deposit Left:`}
        </LoadingText>
        <LoadingText style={styles.text} loading={loading}>
          {`R${nftDetails.depositZar}`}
        </LoadingText>
      </Grid>
      <Grid
        container
        style={{ justifyContent: "space-between", flexDirection: "row" }}
      >
        <LoadingText style={styles.detailsKey} loading={loading}>
          {`Last Bought:`}
        </LoadingText>
        <LoadingText style={styles.text} loading={loading}>
          {`${dayjs.unix(nftDetails.timesAcquired).format("DD MMM YYYY")}`}
        </LoadingText>
      </Grid>
      <Grid
        container
        style={{ justifyContent: "space-between", flexDirection: "row" }}
      >
        <LoadingText style={styles.detailsKey} loading={loading}>
          {`Floor Price:`}
        </LoadingText>
        <LoadingText style={styles.text} loading={loading}>
          {`R${nftDetails.floorPriceZar}`}
        </LoadingText>
      </Grid>
    </Card>
  );
};

const AdoptionCard = (props) => {
  const { steward, web3 } = props;

  const contract = useSelector((state) => state.nft);

  return (
    <Card
      style={styles.card}
      title={<h1 style={styles.title}>{steward.name}</h1>}
    >
      <Image
        src={
          `${steward.imageUri}?w=248&fit=crop&auto=format` ?? steward.imageUrl
        }
        alt="nologo"
        maxWidth="100%"
        //height="24px"
        preview={false}
        style={{ alignSelf: "center", borderRadius: "5px" }}
      />
      <h1 style={styles.description}>{steward.description}</h1>
      <Container
        style={{
          marginTop: 10,
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Link
          target="_blank"
          rel="noreferrer noopener"
          style={{
            fontSize: 20,
            padding: 10,
            textAlign: "center",
            width: "100%",
            alignSelf: "center",
          }}
          href={`https://testnets.opensea.io/assets/mumbai/${contract.nftAddress}/${steward.tokenId}`}
        >
          <Text>View on OpenSea</Text>
        </Link>
      </Container>
    </Card>
  );
};

const ProcessingModal = ({
  numBlocks,
  currentBlock,
  transactionConfirmed,
  onClose,
  transactionMessage,
  transactionStatus,
  transactionHash,
}) => {
  const [open, setOpen] = useState(true);
  const [progress, setProgress] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    (currentBlock / numBlocks) * 100 < 100
      ? setProgress((currentBlock / numBlocks) * 100)
      : setProgress(100);
  }, [currentBlock]);

  const styles = {
    closeButton: {
      fontSize: 30,
      color: "white",
    },
    boxStyle: {
      position: "absolute",
      top: "150px",
      right: "0px",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
      zIndex: 9999,
    },
    card: {
      position: "absolute",
      top: "0px",
      right: "0px",
      //transform: 'translate(-50%, -50%)',
      width: 400,
      //boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
      border: "1px solid #e7eaf3",
      borderColor: "grey",
      borderRadius: "0.5rem",
      background: "#3d4148",
      alignItems: "center",
      zIndex: 9999,
    },
    text: {
      fontSize: "16px",
      fontWeight: "700",
      color: "white",
    },
    title: {
      fontSize: "20px",
      fontWeight: "700",
      color: "white",
    },
    titleSuccess: {
      fontSize: "20px",
      fontWeight: "700",
      color: "green",
    },
    titleError: {
      fontSize: "20px",
      fontWeight: "700",
      color: "red",
    },
  };

  if (transactionStatus === "SUCCESS") {
    return (
      <Card
        style={styles.card}
        title={
          <Grid
            container
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h3 style={styles.title}>Transaction Completed</h3>
            <IconButton onClick={onClose}>
              <Close sx={styles.closeButton} />
            </IconButton>
          </Grid>
        }
      >
        <Grid
          container
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <h3 style={styles.titleSuccess}>{transactionMessage}</h3>
          <CheckCircleOutlined sx={{ fontSize: 50, color: "green" }} />
        </Grid>
        {transactionHash && (
          <Container style={{ alignItems: "flex-start", marginTop: 20 }}>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              style={{ fontSize: 16 }}
              href={`https://mumbai.polygonscan.com/tx/${transactionHash}`}
            >
              Track Here
            </Link>
          </Container>
        )}
      </Card>
    );
  }

  if (transactionStatus === "ERROR") {
    return (
      <Card
        style={styles.card}
        title={
          <Grid
            container
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h3 style={styles.title}>Processing Purchase</h3>
            <IconButton onClick={onClose}>
              <Close sx={styles.closeButton} />
            </IconButton>
          </Grid>
        }
      >
        <Grid
          container
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <h3 style={styles.titleError}>{transactionMessage}</h3>
        </Grid>
        {transactionHash && (
          <Container style={{ alignItems: "flex-start", marginTop: 20 }}>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              style={{ fontSize: 16 }}
              href={`https://mumbai.polygonscan.com/tx/${transactionHash}`}
            >
              Track Here
            </Link>
          </Container>
        )}
      </Card>
    );
  }

  if (transactionStatus === "PENDING") {
    return (
      <Card
        style={styles.card}
        title={
          <Grid
            container
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h3 style={styles.title}>Processing Purchase</h3>
            <IconButton onClick={onClose}>
              <Close sx={styles.closeButton} />
            </IconButton>
          </Grid>
        }
      >
        <Grid
          container
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Text
            style={styles.text}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {transactionMessage}
          </Text>
          <CircularProgress />
        </Grid>
        {transactionHash && (
          <Container style={{ alignItems: "flex-start", marginTop: 20 }}>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              style={{ fontSize: 16 }}
              href={`https://mumbai.polygonscan.com/tx/${transactionHash}`}
            >
              Track Here
            </Link>
          </Container>
        )}
      </Card>
    );
  }

  if (transactionStatus === "PROCESSING") {
    return (
      <Card
        style={styles.card}
        title={
          <Grid
            container
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h3 style={styles.title}>Processing Purchase</h3>
            <IconButton onClick={onClose}>
              <Close sx={styles.closeButton} />
            </IconButton>
          </Grid>
        }
      >
        <Container>
          <Text
            style={styles.text}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {`Confirmation Block ${currentBlock}/${numBlocks}`}
          </Text>
          <LinearProgress variant="determinate" value={progress} />
        </Container>
        {transactionHash && (
          <Container style={{ alignItems: "flex-start", marginTop: 20 }}>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              style={{ fontSize: 16 }}
              href={`https://mumbai.polygonscan.com/tx/${transactionHash}`}
            >
              Track Here
            </Link>
          </Container>
        )}
      </Card>
    );
  }

  return (
    <Card
      style={styles.card}
      title={
        <Grid
          container
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <h3 style={styles.title}>Processing Purchase</h3>
          <IconButton onClick={onClose}>
            <Close sx={styles.closeButton} />
          </IconButton>
        </Grid>
      }
    >
      <Grid
        container
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Text
          style={styles.text}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          {`Waiting for transaction to start`}
        </Text>
        <CircularProgress />
      </Grid>
      {transactionHash && (
        <Container style={{ alignItems: "flex-start" }}>
          <Link
            target="_blank"
            rel="noreferrer noopener"
            style={{ fontSize: 16 }}
            href={`https://mumbai.polygonscan.com/tx/${transactionHash}`}
          >
            Track Here
          </Link>
        </Container>
      )}
    </Card>
  );
};

export default function NftDetails() {
  const location = useLocation();
  const { steward } = location.state;

  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState("IDLE");
  const [transactionMessage, setTransactionMessage] = useState("");
  const [transactionConfirmed, setTransactionConfirmed] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState(0);
  const [transactionHash, setTransactionHash] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingModalVisible, setProcessingModalVisible] = useState(false);
  const { Moralis, web3, isAuthenticated, isWeb3Enabled } = useMoralis();

  const confirmationNumberRef = useRef(confirmationNumber);

  const loadedNft = useSelector((state) =>
    selectLoadedNftById(state, steward.tokenId)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    confirmationNumberRef.current = confirmationNumber;
  }, [confirmationNumber]);

  const loadNftDetails = async () => {
    setLoading(true);
    await dispatch(fetchNFTDetails({ web3, tokenId: steward.tokenId }));
    setLoading(false);
  };

  const adoptionHandler = async (price) => {
    setTransactionHash("");
    setTransactionConfirmed(false);
    setIsProcessing(true);
    setProcessingModalVisible(true);
    setConfirmationNumber(0);
    setTransactionStatus("PENDING");
    setTransactionMessage("Waiting for transaction");
    let accounts = await web3.eth.getAccounts();
    const buyPrice = new web3.utils.BN(
      web3.utils.toWei(String(price), "ether")
    );
    const sellPrice = new web3.utils.BN(
      web3.utils.toWei(String(loadedNft.currentPrice), "ether")
    );

    let stewardContract = new web3.eth.Contract(STEWARD_ABI, STEWARD_ADDRESS);
    console.log("buyPrice: ", buyPrice);
    //await stewardContract.methods.buy(buyPrice, 0, steward.tokenId).send();

    console.log("account[0]: ", accounts[0]);

    console.log(`buy(${buyPrice}, ${sellPrice}, ${steward.tokenId})`);
    await stewardContract.methods
      .buy(buyPrice, sellPrice, steward.tokenId)
      .send({
        from: accounts[0],
        value: buyPrice.add(sellPrice),
      })
      .on("transactionHash", async function (hash) {
        console.log(`Tx hash: ${hash}`);
        setTransactionHash(hash);
        setTransactionMessage("Transaction Started");
        setTransactionStatus("PENDING");
      })
      .on("confirmation", async function (confirmationNumber, receipt) {
        if (confirmationNumber < 20) {
          if (!transactionConfirmed) {
            setConfirmationNumber(confirmationNumber + 1);
            setTransactionStatus("PROCESSING");
            setTransactionMessage("Processing transaction");
            console.log(`Confirmed ${confirmationNumber}`);
            console.log("confirmationNumber", confirmationNumber);
            console.log("receipt", receipt);
          }
        } else {
          setTransactionStatus("SUCCESS");
          setTransactionMessage("Transaction successful");
          setTransactionConfirmed(true);
          loadNftDetails();
        }
      })
      .on("receipt", async function (receipt) {
        console.log(`Tx Confirmed`);
        console.log("receipt", receipt);
        if (!transactionConfirmed) {
          setTransactionStatus("SUCCESS");
          setTransactionMessage("Transaction successful");
          setTransactionConfirmed(true);
          loadNftDetails();
        }
      })
      .on("error", function (error, receipt) {
        setTransactionStatus("ERROR");
        setTransactionMessage("Transaction failed");
        console.log("error", error);
        console.log("receipt", receipt);
        // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
      });
    setIsProcessing(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      console.log("steward: ", steward);
      console.log("Authenticated. Loading NFTs");
      loadNftDetails();
    }
  }, [isAuthenticated]);

  //Object.keys(nftDetails).length !== 0
  if (Object.keys(loadedNft ?? {}).length !== 0) {
    return (
      <Container id={"main_container"} style={styles.mainContainer}>
        <Grid container style={{ flex: 1, width: "100%" }}>
          <AdoptionCard
            steward={steward}
            web3={web3}
            nftAddress={steward.nftAddress}
          />
          <Grid>
            <DetailsCard nftDetails={loadedNft} loading={loading} />
            <PriceCard
              onSubmit={adoptionHandler}
              nftDetails={loadedNft}
              loading={loading}
            />
          </Grid>
        </Grid>

        {processingModalVisible && (
          <ProcessingModal
            numBlocks={20}
            currentBlock={confirmationNumberRef.current}
            transactionConfirmed={transactionConfirmed}
            transactionStatus={transactionStatus}
            transactionMessage={transactionMessage}
            transactionHash={transactionHash}
            onClose={() => setProcessingModalVisible(false)}
          />
        )}
      </Container>
    );
  }

  return (
    <div style={{ display: "grid", gap: "10px" }}>
      <div>
        {loading && <CircularProgress />}
        <h1
          style={{
            ...styles.title,
            color: "black",
          }}
        >
          {isAuthenticated ? "Loading..." : "Authentication required"}
        </h1>
        {!loading && isAuthenticated && (
          <Button
            type="primary"
            size="large"
            style={{
              width: "100%",
              marginTop: "15px",
              borderRadius: "0.6rem",
              height: "50px",
              backgroundColor: "#A4CC28",
            }}
            onClick={() => loadNftDetails()}
            disabled={false}
          >
            Reload NFT
          </Button>
        )}
      </div>
    </div>
  );
}
